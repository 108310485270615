import axios from 'axios'

import { useEffect } from 'react'

import { getShipments } from 'src/common/services/shipmentsService'
import { useGlobalStore } from 'src/common/stores/GlobalStore'

import { Shipment } from '../types/shipments'

const notFoundCode = 404

export const filterShelves = (
  shipments: Shipment[],
  shouldFilter: boolean
): Shipment[] => {
  if (!shouldFilter) return shipments

  return shipments.filter(shipment => shipment.numberOfAdditionalLayers > 0)
}

export const useShipments = (): void => {
  const { filters, setTotals, setShipments, setQueryState, resetShipmentsAndTotals } =
    useGlobalStore()

  const handleShipmentsRequest = async () => {
    setQueryState('loading')
    try {
      const { data: responseData } = await getShipments(filters)
      responseData.shipments = responseData.shipments.filter(
        shipment => shipment.loadCarrierType === 'AUCTION_TROLLEY'
      )

      setTotals(responseData.totals)
      setShipments(filterShelves(responseData.shipments, filters.shelvesToggle))
      setQueryState('found')
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === notFoundCode) {
        resetShipmentsAndTotals()
        setQueryState('nothing')
      } else if (axios.isAxiosError(error)) {
        resetShipmentsAndTotals()
        setQueryState('error')
      } else {
        setQueryState('error')
      }
    }
  }

  useEffect(() => {
    const timeoutDuration = 300
    const timeoutHandler = setTimeout(() => {
      handleShipmentsRequest()
    }, timeoutDuration)
    return () => {
      clearTimeout(timeoutHandler)
    }
  }, [filters])
}
