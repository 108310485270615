import axios, { AxiosRequestConfig } from 'axios'

import { AccessToken } from '@okta/okta-auth-js'

import { oktaAuth } from 'src/common/utils/auth'
import { Config } from 'src/configs/Config'

const ApiInstance = axios.create({
  baseURL: Config.api.host,
})

const getAccessToken = async () => {
  let accessToken: AccessToken = await oktaAuth.tokenManager.get('accessToken')
  const { expireEarlySeconds } = oktaAuth.tokenManager.getOptions()
  const expirationOffsetFallback = 30
  const expirationOffset = expireEarlySeconds || expirationOffsetFallback
  const timeToSeconds = 1000

  // Normally token is renewed automatically.
  // But it can occur that the token cannot be renewed.
  // For this scenario this fallback is added.
  if (
    accessToken &&
    Math.floor(new Date().getTime() / timeToSeconds) >
      accessToken.expiresAt - expirationOffset
  ) {
    accessToken = (await oktaAuth.tokenManager.renew('accessToken')) as AccessToken
  }

  return accessToken
}

export const requestHandler = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const accessToken = await getAccessToken()

  if (!accessToken) {
    await oktaAuth.signInWithRedirect()
  } else {
    config.headers = {
      Authorization: `Bearer ${accessToken.accessToken}`,
    }
  }

  return config
}

ApiInstance.interceptors.request.use(requestHandler)

export { ApiInstance as Api }
