import { useEffect, useState } from 'react'

export type useLoadMoreListDataProps<ListDataType> = {
  listData: ListDataType[]
  numberOfItemsToLoad: number
  initShowNumberOfItems: number
}
export const useLoadMoreListData = <ListDataType>({
  numberOfItemsToLoad,
  listData,
  initShowNumberOfItems,
}: useLoadMoreListDataProps<ListDataType>): {
  loadMoreShipmentOrders: () => void
  hasShowMore: boolean
  newList: ListDataType[]
} => {
  const [index, setIndex] = useState<number>(initShowNumberOfItems - 1)
  const [hasShowMore, setHasShowMore] = useState<boolean>(false)
  const [list, setList] = useState<ListDataType[]>([])

  useEffect(() => {
    if (listData.length) {
      const initList = listData.filter((_item, initIndex) => {
        return initIndex <= initShowNumberOfItems - 1
      })
      setList(initList)
      setHasShowMore(initShowNumberOfItems <= listData.length)
    }
  }, [listData])

  useEffect(() => {
    setHasShowMore(index < listData.length - 1)
  }, [index])

  const loadMoreShipmentOrders = (): void => {
    const newIndex = index + numberOfItemsToLoad
    setIndex(newIndex)
    const afterNewList = listData.filter((_item, listDataNewIndex) => {
      return listDataNewIndex <= newIndex
    })
    setList(afterNewList)
  }
  return {
    loadMoreShipmentOrders,
    hasShowMore,
    newList: list,
  }
}
