import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'

import { Cookiebar } from '@rfh/ui/components/Cookiebar'
import { RfhTheme } from '@rfh/ui/components/RfhTheme'

import './App.css'
import { Routes } from './common/components/Routes'
import { AccountStoreProvider } from './common/stores/AccountStore'
import { GlobalStoreProvider } from './common/stores/GlobalStore'

const App: FC = () => {
  const { t } = useTranslation()

  return (
    <RfhTheme>
      <Router>
        <AccountStoreProvider>
          <GlobalStoreProvider>
            <Routes />
            <Cookiebar
              title={t('general:cookieBar.cookieStatement')}
              messageText={t('general:cookieBar.useCookies')}
              linkText={t('general:cookieBar.moreInformation')}
              linkHref={t('general:cookieBar.cookieStatementUrl')}
            />
          </GlobalStoreProvider>
        </AccountStoreProvider>
      </Router>
    </RfhTheme>
  )
}

export default App
