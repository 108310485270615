import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next, Namespace, TFunction } from 'react-i18next'

import FlagDE from '@rfh/ui/shared/icons/FlagDE'
import FlagGB from '@rfh/ui/shared/icons/FlagGB'
import FlagNL from '@rfh/ui/shared/icons/FlagNL'

import { customLanguageDetector } from './customLanguageDetector'
import deTranslations from './de'
import enTranslations from './en'
import nlTranslations from './nl'

export type AppLanguageCode = 'nl' | 'de' | 'en'
export type AppNamespace = Record<'general' | 'shipments', any>
export type AppTFunction = TFunction<Namespace<keyof AppNamespace>>
export const fallbackLng = 'en' as AppLanguageCode
export type AppLanguageConfig = Record<
  AppLanguageCode,
  { text: string; flagIcon: JSX.Element; languageName: Record<AppLanguageCode, string> }
>

export const appLanguageConfig: AppLanguageConfig = {
  en: {
    text: 'Change language',
    flagIcon: <FlagGB />,
    languageName: {
      de: 'German',
      en: 'English',
      nl: 'Dutch',
    },
  },
  nl: {
    text: 'Verander taal',
    flagIcon: <FlagNL />,
    languageName: {
      de: 'Duits',
      en: 'Engels',
      nl: 'Nederlands',
    },
  },
  de: {
    text: 'Sprache ändern',
    flagIcon: <FlagDE />,
    languageName: {
      de: 'Deutsch',
      en: 'Englisch',
      nl: 'Niederlandisch',
    },
  },
}

export const resources = {
  de: {
    ...deTranslations,
  },
  en: {
    ...enTranslations,
  },
  nl: {
    ...nlTranslations,
  },
} as const

export const allowedLanguages = Object.keys(resources)

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customLanguageDetector)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    fallbackLng,
    ns: ['general', 'shipments'] as (keyof AppNamespace)[],
    defaultNS: 'general',
    resources,
    detection: {
      caches: ['localStorage', 'customLanguageDetector'],
      order: ['localStorage', 'customLanguageDetector'],
    },
  })

export default i18n

export const openingshoursUrl = {
  nl: 'https://www.royalfloraholland.com/nl/inkopen/logistiek/logistieke-middelen/fusten/fust/inname-en-uitgifte-fust',
  en: 'https://www.royalfloraholland.com/en/buying/logistics/logistic-supplies/packaging/collecting-and-returning-packaging',
  de: 'https://www.royalfloraholland.com/de/einkauf/logistik/logistische-mittel/alles-%C3%BCber-verpackungen/ab-und-ausgabe-von-verpackungsmaterial',
}

export const moreInformationUrl = {
  nl: 'https://www.royalfloraholland.com/nl/inkopen/logistiek/logistieke-middelen/fust-online-nod55779',
  en: 'https://www.royalfloraholland.com/en/buying/logistics/logistic-supplies/packaging-online-nod67211',
  de: 'https://www.royalfloraholland.com/de/verpackung-online',
}
