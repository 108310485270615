import { observer } from 'mobx-react-lite'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { useGlobalStore } from '../stores/GlobalStore'
import { formatDate } from '../utils/format'
import { Info, InfoCard } from './InfoCard'

export const ShipmentsTotals: FC = observer(() => {
  const { filters, totals } = useGlobalStore()
  const { t } = useTranslation()

  const infoLadingdragers: Info = {
    topTotal: totals.stapelwagens,
    topTitle: t('shipments:totalTrolleys'),
    leftTotal: totals.stapelwagens,
    leftTitle: t('shipments:auctionTrolleys'),
    rightTotal: null,
    rightTitle: '',
  }

  const infoLegborden: Info = {
    topTotal: totals.bledden,
    topTitle: t('shipments:totalShelves'),
    leftTotal: totals.bledden,
    leftTitle: t('shipments:trays'),
    rightTotal: null,
    rightTitle: '',
  }

  return (
    <Grid data-testid='shipments-totals' container spacing={3}>
      <Grid item md={6} xs={12}>
        <InfoCard
          color={'royalPurple'}
          info={infoLadingdragers}
          firstChip={formatDate(filters.date)}
          secondChip={filters.rfhWarehouse.name}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <InfoCard
          color={'hydroGreen'}
          info={infoLegborden}
          firstChip={formatDate(filters.date)}
          secondChip={filters.rfhWarehouse.name}
        />
      </Grid>
    </Grid>
  )
})
