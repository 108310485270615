import { styled } from '@mui/material'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

export const Label = styled('div')(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1.5),
}))

export const FilterButton = styled(RfhButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))
