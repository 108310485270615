import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, useContext } from 'react'

import {
  Filters,
  Location,
  LocationCode,
  LocationName,
  QueryState,
  Shipment,
  Totals,
} from '../types/shipments'
import { getValueFromLocalStorage } from '../utils/getValueFromLocalStorage'

const DEFAULT_FILTERS = {
  date: new Date(),
  rfhWarehouse: {
    code: LocationCode.Aalsmeer,
    name: LocationName.Aalsmeer,
  },
  shipmentId: '',
  salesOrderId: '',
  shelvesToggle: false,
}

export interface GlobalStore {
  shipments: Shipment[]
  totals: Totals
  filters: Filters
  queryState: QueryState
  setQueryState: (newState: QueryState) => void
  drawerIsOpen: boolean
  setDrawerIsOpen: (newState: boolean) => void
  setFilter: (
    filter: { [key: string]: string | Date | boolean } | { rfhWarehouse: Location }
  ) => void
  resetFilters: () => void
  setShipments: (shipments: Shipment[]) => void
  setTotals: (totals: Totals) => void
  resetShipmentsAndTotals: () => void
}

export const GlobalStoreContext = createContext<GlobalStore | null>(null)

export const initialTotals: Totals = {
  stapelwagens: 0,
  denen: 0,
  bledden: 0,
  platen: 0,
}

const filters = getValueFromLocalStorage('rfh-filters', DEFAULT_FILTERS) as Filters

export const initialState: GlobalStore = {
  shipments: [],
  totals: initialTotals,
  filters: {
    date: new Date(filters.date),
    rfhWarehouse: filters.rfhWarehouse,
    shipmentId: filters.shipmentId,
    salesOrderId: filters.salesOrderId,
    shelvesToggle: filters.shelvesToggle,
  },
  queryState: 'loading',
  setQueryState(newState: QueryState) {
    this.queryState = newState
  },
  drawerIsOpen: false,
  setDrawerIsOpen(newState: boolean) {
    this.drawerIsOpen = newState
  },
  setFilter(
    filter: { [key: string]: string | Date | boolean } | { rfhWarehouse: Location }
  ) {
    this.filters = { ...this.filters, ...filter }
    localStorage.setItem('rfh-filters', JSON.stringify(this.filters))
  },
  resetFilters() {
    this.filters = {
      ...DEFAULT_FILTERS,
    }
    localStorage.setItem('rfh-filters', JSON.stringify(this.filters))
  },
  setShipments(shipments: Shipment[]) {
    this.shipments = shipments
  },
  setTotals(totals: Totals) {
    this.totals = totals
  },
  resetShipmentsAndTotals() {
    this.totals = initialTotals
    this.shipments = []
  },
}

// Added `initial` prop for testing purposes.
// In the application we should not provide this property and just use the default value.
export const GlobalStoreProvider: FC<{ initial?: GlobalStore }> = ({
  children,
  initial = initialState,
}) => {
  const globalStore: GlobalStore = useLocalObservable(() => initial)

  return (
    <GlobalStoreContext.Provider value={globalStore}>
      {children}
    </GlobalStoreContext.Provider>
  )
}

export const useGlobalStore = (): GlobalStore => {
  const store = useContext(GlobalStoreContext)

  if (!store) {
    throw new Error('No global store defined')
  }

  return store
}
