import { OktaAuthOptions } from '@okta/okta-auth-js'

export const scopeDomainPrefix = 'ala-shipment-'
const redirectUri = `${window.location.origin}/callback`

const pipelineValues = {
  envValue: '#{OktaOmgeving}#',
  oktaAuthentication: '#{OktaAuthentication}#',
  apiHostValue: '#{ApiHost}#',
  postLogoutRedirectUriValue: '#{OktaPostLogoutRedirectUri}#',
  clientIdValue: '#{OktaClientId}#',
  domainValue: '#{OktaDomain}#',
  issuerValue: '#{OktaIssuer}#',
  scopeValue: '#{OktaScope}#',
  sentryEnvironment: '#{Omgeving}#',
}

const localValues = {
  envValue: 'sys',
  oktaAuthentication: 'on',
  apiHostValue: 'https://ala-to-shipment-api.operstaging.royalfloraholland.com',
  postLogoutRedirectUriValue: 'https://sts-sys.floraholland.nl/adfs/ls/?wa=wsignout1.0',
  clientIdValue: '0oa1uy2k8sVb7c8Uu0x7',
  domainValue: 'https://identity-test.royalfloraholland.com/oauth2/default',
  issuerValue: 'https://identity-test.royalfloraholland.com/oauth2/default',
  scopeValue: scopeDomainPrefix + 'api-',
  sentryEnvironment: 'sys',
}

type TKeyType = keyof typeof pipelineValues

// The {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used.
Object.keys(pipelineValues).forEach((key): void => {
  if (pipelineValues[key as TKeyType].startsWith('#')) {
    pipelineValues[key as TKeyType] = localValues[key as TKeyType]
  }
})

const oktaScopes = [
  'openid',
  'profile',
  'email',
  pipelineValues.scopeValue + pipelineValues.envValue,
]

export type AppConfig = {
  env: 'dev' | 'sys' | 'acc' | 'prd' | string
  oktaRoute: string
  api: {
    host: string
    path: string
    version: string
  }
  oidc: OktaAuthOptions
  scopeDomainPrefix: string
  defaultRefreshInterval: number
  maxRetryDelay: number
  sentryScope: string
  sentryEnvironment: string
}
export const Config: AppConfig = {
  env: pipelineValues.envValue,
  oktaRoute: pipelineValues.oktaAuthentication,
  api: {
    host: pipelineValues.apiHostValue,
    path: '',
    version: '',
  },
  oidc: {
    clientId: pipelineValues.clientIdValue,
    issuer: pipelineValues.issuerValue,
    redirectUri,
    postLogoutRedirectUri: pipelineValues.postLogoutRedirectUriValue,
    scopes: oktaScopes,
    pkce: process.env.NODE_ENV === 'test' ? false : true,
  },
  scopeDomainPrefix,
  defaultRefreshInterval: 25000,
  maxRetryDelay: 30000,
  sentryScope:
    'https://6550227ca83b4ea8bb10621e1e5a746a@o902103.ingest.sentry.io/5842595',
  sentryEnvironment: pipelineValues.sentryEnvironment,
}

export type PrefixPermissionScope = 'ala-shipment-isklant-'

export const detectScopePerEnv = (
  prefix: PrefixPermissionScope,
  env = Config.env
): string => {
  return prefix + env
}
