import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { RfhTypography } from '@rfh/ui/components/RfhTypography'

import { useGlobalStore } from '../stores/GlobalStore'
import { ExpandCollapseAllButton, ShipmentExpandIcon } from './ShipmentsList.styles'

export const ShipmentsListHeader: FC<{
  handleClick: () => void
  shouldCollapseAll: boolean
}> = ({ handleClick, shouldCollapseAll }) => {
  const { t } = useTranslation()
  const { queryState } = useGlobalStore()

  return (
    <Box mt={4}>
      <Grid container alignItems='stretch'>
        <Grid item xs>
          <RfhTypography variant='h5'>{t('shipments:shipments')}</RfhTypography>
        </Grid>
        <Grid item xs>
          {queryState === 'found' && (
            <ExpandCollapseAllButton
              align='right'
              variant='text'
              onClick={handleClick}
              data-testid='expand-collapse-button'
            >
              {shouldCollapseAll
                ? t('shipments:collapseAll')
                : t('shipments:expandAll')}
              <ShipmentExpandIcon margin={0} expanded={shouldCollapseAll ? 1 : 0} />
            </ExpandCollapseAllButton>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
