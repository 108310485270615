import { History } from 'history'

import { FC } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { SecureRoute, Security } from '@okta/okta-react'

import { NotFound } from 'src/views/NotFound'
import { Shipments } from 'src/views/Shipments'
import { Callback } from 'src/views/okta/Callback'
import { Login } from 'src/views/okta/Login'
import { Logout } from 'src/views/okta/Logout'

import { NoPermission } from '../../views/NoPermission'
import { oktaAuth } from '../utils/auth'

export const restoreOriginalUri =
  (history: History) =>
  (_oktaAuth: OktaAuth, originalUri = '/'): void => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }
export const onAuthRequired = (history: History) => (): void => {
  history.push('/login')
}

export const Routes: FC = () => {
  const history = useHistory()

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri(history)}
      onAuthRequired={onAuthRequired(history)}
    >
      <Switch>
        <SecureRoute exact path='/' component={Shipments} />
        <Route path='/login' component={Login} />
        <Route path='/logout' component={Logout} />
        <Route path='/callback' component={Callback} />
        <Route path='/no-permission' component={NoPermission} />
        <Route component={NotFound} />
      </Switch>
    </Security>
  )
}
