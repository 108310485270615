import { FC } from 'react'

import { Box, styled } from '@mui/material'
import { alpha } from '@mui/material/styles/'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { Order } from '../types/shipments'

const Row = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}))

const HeadRow = styled(Row)(({ theme }) => ({
  backgroundColor: theme.rfhColors.darkGrey,
  fontWeight: 700,
  color: theme.rfhColors.white,
  textTransform: 'uppercase',
}))

const BodyRow = styled(Row)<{ even: number }>(({ theme, even }) => ({
  backgroundColor:
    even === 0 ? theme.rfhColors.white : alpha(theme.rfhColors.fogWhite as string, 0.6),
}))

export const SalesOrders: FC<{ salesOrders: Order[] }> = ({ salesOrders, ...rest }) => (
  <Box bgcolor={RfhColors.white} {...rest}>
    <HeadRow>Order ID</HeadRow>
    {salesOrders.map(({ salesOrderId }, index) => (
      <BodyRow key={salesOrderId} even={index % 2}>
        {salesOrderId}
      </BodyRow>
    ))}
  </Box>
)
