import ReactDOM from 'react-dom'
import TagManager, { TagManagerArgs } from 'react-gtm-module'

import App from './App'
import './index.css'

if (process.env.NODE_ENV === 'development') {
  const loadServer = async () => {
    const { makeServer } = await import('./mirage/server')
    makeServer({ environment: 'development' })
  }

  loadServer()
}

const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-MNFTH76',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById('root'))
