import {
  rfhIntlDateTimeFormat,
  RfhIntlDateTimeFormatOptions,
} from '@rfh/ui/shared/utils/lib'

import i18n from '../../i18n/config'

export const formatDate = (
  value: RfhIntlDateTimeFormatOptions['value'] | string,
  options?: {
    display: RfhIntlDateTimeFormatOptions['display']
    dateStyle?: RfhIntlDateTimeFormatOptions['dateStyle']
  }
): string =>
  rfhIntlDateTimeFormat({
    locales: i18n.language,
    display: options?.display || 'date',
    value: value as Date,
    dateStyle: options?.dateStyle || 'medium',
  })
