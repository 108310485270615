export type Order = {
  salesOrderId: string
}

export enum LocationName {
  Aalsmeer = 'Aalsmeer',
  Naaldwijk = 'Naaldwijk',
  Eelde = 'Eelde',
  Rijnsburg = 'Rijnsburg',
}

export enum LocationCode {
  Aalsmeer = 1,
  Naaldwijk = 2,
  Eelde = 7,
  Rijnsburg = 3,
}

export type Location = { code: LocationCode; name: LocationName }

export type Filters = {
  date: Date
  rfhWarehouse: Location
  shipmentId: string
  salesOrderId: string
  shelvesToggle: boolean
}

export type Shipment = {
  id: string
  shipmentId: string | undefined
  shipmentDateTime: string
  rfhWarehouse: Location
  salesOrders: Order[]
  loadCarrierType: 'AUCTION_TROLLEY' | 'DANISH_TROLLEY'
  numberOfAdditionalLayers: number
}

export type QueryState = 'loading' | 'found' | 'nothing' | 'error'

export type Totals = {
  stapelwagens: number
  denen: number
  bledden: number
  platen: number
}

export type ShipmentsApiResponse = {
  shipments: Shipment[]
  totals: Totals
}
