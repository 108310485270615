import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState, EmptyStateIcon } from '@rfh/ui/components/EmptyState'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

import { Layout } from '../common/components/Layout'

export const NoPermission: FC = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <EmptyState
        bottomComponent={
          <RfhButton
            href={t('noPermission.contactCenterLink')}
            variant='block--contained'
          >
            {t('general:noPermission.button')}
          </RfhButton>
        }
        graphic={<EmptyStateIcon />}
        header={t('general:noPermission.header')}
        subheader={t('general:noPermission.subheader')}
      />
    </Layout>
  )
}
