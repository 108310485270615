import { AxiosResponse } from 'axios'

import { Api } from 'src/common/utils/api'

export type BuyerApiResponse = {
  buyer: string
}

export const getBuyer = async (): Promise<AxiosResponse<BuyerApiResponse>> =>
  Api.get<BuyerApiResponse>('/buyer')
