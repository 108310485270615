import { observer } from 'mobx-react-lite'

import { FC } from 'react'

import { Box } from '@mui/material'
import { RfhTypography } from '@rfh/ui/components/RfhTypography'

import { useAccountStore } from '../stores/AccountStore'

export const FilterHeader: FC<{
  message: string
}> = observer(({ message, ...rest }) => {
  const { buyer, user } = useAccountStore()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      data-testid='text-card'
      mb={2}
      {...rest}
    >
      <Box mb={1}>
        <RfhTypography variant='h5'>
          {buyer ? buyer : '\u00A0'}
          <span style={{ fontWeight: 300 }}>
            {user?.customerNumber ? ` (${user.customerNumber})` : null}
          </span>
        </RfhTypography>
      </Box>
      <RfhTypography variant='body-copy'>{message}</RfhTypography>
    </Box>
  )
})
