import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

export const Callback: FC = () => {
  const { oktaAuth } = useOktaAuth()
  const history = useHistory()

  const handleLoginRedirect = async () => {
    try {
      await oktaAuth.handleLoginRedirect()
    } catch (error) {
      if (
        (error as string).toString() ===
        'OAuthError: User is not assigned to the client application.'
      ) {
        history.push('/no-permission')
      }
    }
  }
  useEffect(() => {
    handleLoginRedirect()
  }, [oktaAuth])

  return null
}
