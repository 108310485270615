import { observer } from 'mobx-react-lite'

import { FC, MouseEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import Popper from '@mui/material/Popper'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { Shipment } from '../types/shipments'
import { ColorCircle } from './ColorCircle'
import { SalesOrders } from './SalesOrders'
import {
  Label,
  LayersAmountLabel,
  ShipmentCarrierIcon,
  ShipmentExpandIcon,
  ShipmentPopper,
} from './ShipmentsList.styles'

export const ShipmentsListItem: FC<{
  shipment: Shipment
  expand: boolean
  handleClick: (event: MouseEvent<HTMLDivElement>) => void
}> = observer(({ shipment, handleClick, expand }) => {
  const infoCircleRef = useRef<HTMLDivElement | null>(null)
  const [showShipmentId, setShowShipmentId] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const handleOnMouseEnter = (event: MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget)
    setShowShipmentId(true)
  }

  const handleOnMouseLeave = (): void => {
    setShowShipmentId(false)
    setAnchorEl(null)
  }

  const toggleExpand = (event: MouseEvent<HTMLDivElement>): void => {
    // This prevents expand on info circle click
    if (
      infoCircleRef.current &&
      !infoCircleRef.current?.contains(event.target as Node)
    ) {
      handleClick(event)
    }
  }

  return (
    <Box data-testid='shipment-list-item'>
      <Box
        style={{ cursor: 'pointer' }}
        onClick={toggleExpand}
        bgcolor={RfhColors.white}
        p={[2, 2, 3]}
        mt={2}
        data-testid='shipment-list-item-row'
      >
        <Grid container alignItems='center'>
          <Grid item sm={6} xs>
            <Box display='flex' mr={1} alignItems='center'>
              <ShipmentCarrierIcon />
              <Label>{t('shipments:shipment')}</Label>
              {shipment.shipmentId ? (
                <>
                  <Box mr={1}>...{shipment.shipmentId.slice(-6)}</Box>
                  <Box
                    mr={1}
                    color={RfhColors.darkGrey}
                    fontSize={12}
                    display={['none', 'flex']}
                    alignItems='center'
                  >
                    ({t('shipments:cartID')})
                  </Box>
                  <ColorCircle
                    ref={infoCircleRef}
                    data-testid='full-shipment-id'
                    color='dutchOrange'
                    text='i'
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Popper open={showShipmentId} anchorEl={anchorEl} placement='top'>
                    <ShipmentPopper>
                      {`${t('shipments:shipmentNumber')}: ${shipment.shipmentId}`}
                    </ShipmentPopper>
                  </Popper>
                </>
              ) : (
                <Box mr={1}>{t('shipments:noCartID')}</Box>
              )}
            </Box>
          </Grid>
          <Grid item sm xs>
            <Box display={['none', 'none', 'flex']} mr={1}>
              <Label>{t('shipments:type')}:</Label>
              {t(`shipments:${shipment.loadCarrierType}`)}
            </Box>
            <Box display={['none', 'none', 'flex']} mr={1}>
              <Label>{t('shipments:location')}:</Label>
              {shipment.rfhWarehouse.name}
            </Box>
          </Grid>
          <Grid item sm xs>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <LayersAmountLabel>
                {shipment.loadCarrierType === 'AUCTION_TROLLEY' ? 'Bledden' : 'Platen'}
              </LayersAmountLabel>
              <ColorCircle
                color='hydroGreen'
                text={shipment.numberOfAdditionalLayers}
              />
              <ShipmentExpandIcon expanded={expand ? 1 : 0} margin={2} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor={RfhColors.lightGrey} p={2} display={['flex', 'flex', 'none']}>
        <Box display={'inline-flex'} mr={1}>
          <Label>{t('shipments:type')}:</Label>
          {t(`shipments:${shipment.loadCarrierType}`)}
        </Box>
        <Box display={'inline-flex'} mr={1}>
          <Label>{t('shipments:location')}:</Label>
          {shipment.rfhWarehouse.name}
        </Box>
      </Box>
      <Collapse in={expand}>
        <SalesOrders
          salesOrders={shipment.salesOrders}
          data-testid={'orders-from-shipment_' + shipment.shipmentId}
        />
      </Collapse>
    </Box>
  )
})
