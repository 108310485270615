import jwt_decode from 'jwt-decode'

import { useEffect } from 'react'

import { useOktaAuth } from '@okta/okta-react'

import { getBuyer } from '../services/buyerService'
import { useAccountStore } from '../stores/AccountStore'

export const useUserInformation = (): void => {
  const { setUser, setAuthState, setPermittedScopes, setBuyer } = useAccountStore()
  const { oktaAuth, authState } = useOktaAuth()

  const storeUserInformation = async () => {
    if (authState?.isAuthenticated) {
      const user = await oktaAuth.getUser()

      const decoded: any = jwt_decode(authState.accessToken?.accessToken as string)
      const { klntnr } = decoded
      const decodedKeys: string[] = Object.keys(decoded)
      const permittedScopes: string[] = decodedKeys.filter(
        item => decoded[item] === 'true'
      )

      const userExtended = {
        ...user,
        customerNumber: Number(klntnr),
      }

      try {
        const { data: responseData } = await getBuyer()
        setBuyer(responseData.buyer)
      } catch (error) {
        console.error(error)
      }

      setUser(userExtended)
      setAuthState(authState)
      setPermittedScopes(permittedScopes)
    }
  }

  useEffect(() => {
    storeUserInformation()
  }, [])
}
