import { observer } from 'mobx-react-lite'

import { FC, MouseEvent, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Switch, Box } from '@mui/material'
import { DatePicker } from '@rfh/ui/components/DatePickers'
import RfhSelect, { SelectItemProps } from '@rfh/ui/components/Inputs/RfhSelect'
import TextField from '@rfh/ui/components/Inputs/TextField'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

import { useGlobalStore, GlobalStore } from '../stores/GlobalStore'
import { LocationCode, LocationName } from '../types/shipments'
import { Label, FilterButton } from './FilterForm.styles'

const locationItems: { label: LocationName; value: LocationCode }[] = [
  { label: LocationName.Aalsmeer, value: LocationCode.Aalsmeer },
  { label: LocationName.Eelde, value: LocationCode.Eelde },
  { label: LocationName.Naaldwijk, value: LocationCode.Naaldwijk },
  { label: LocationName.Rijnsburg, value: LocationCode.Rijnsburg },
]

export const FilterForm: FC = observer(() => {
  const { t } = useTranslation()
  const { filters, setFilter, resetFilters, setDrawerIsOpen } = useGlobalStore()

  const handleDateSelection = (value: Date) => {
    setFilter({
      date: value,
    })
  }

  const handleWarehouseSelection = (_event: MouseEvent, item: SelectItemProps) => {
    setFilter({
      rfhWarehouse: {
        code: item.value as LocationCode,
        name: item.label as LocationName,
      },
    })
  }

  const handleTextfieldFilter =
    (filterLabel: keyof GlobalStore['filters']) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilter({ [filterLabel]: event.target.value })
    }

  const handleShelvesToggle = (checked: boolean) =>
    setFilter({ shelvesToggle: checked })

  const handleFilterClick = () => {
    setDrawerIsOpen(false)
  }

  return (
    <Grid container spacing={2} rowSpacing={3}>
      <Grid item md={12} xs={6}>
        <Label>{t('shipments:deliveryDate')}</Label>
        <Box height={'40px'} position={'relative'} zIndex={10}>
          <DatePicker value={filters.date} onClickDay={handleDateSelection} />
        </Box>
      </Grid>
      <Grid item md={12} xs={6} data-testid='select-location'>
        <Label>{t('shipments:branchLocation')}</Label>
        <RfhSelect
          defaultValue={filters.rfhWarehouse.code}
          items={locationItems}
          onSelectItemClick={handleWarehouseSelection}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>{t('shipments:shipmentCartID')}</Label>
        <TextField
          id='shipmentId'
          placeholder={t('shipments:last6digits')}
          fullWidth
          value={filters.shipmentId}
          onChange={handleTextfieldFilter('shipmentId')}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>{t('shipments:orderTransacttionID')}</Label>
        <TextField
          id='salesOrderId'
          placeholder={t('shipments:orderTransacttionID')}
          fullWidth
          value={filters.salesOrderId}
          onChange={handleTextfieldFilter('salesOrderId')}
        />
      </Grid>
      <Grid item xs={12}>
        <Label>{t('shipments:shelvesToggleTitle')}</Label>
        <Box display={'flex'} justifyContent={'space-between'}>
          <label htmlFor='shelvesToggle'>{t('shipments:shelvesToggleLabel')}</label>
          <Switch
            sx={{ marginLeft: 1 }}
            id='shelvesToggle'
            checked={filters.shelvesToggle}
            onChange={(_event, checked) => {
              handleShelvesToggle(checked)
            }}
          />
        </Box>
      </Grid>
      <Grid item md={12} xs={6}>
        <FilterButton
          aria-label='Filter'
          variant='block--contained'
          onClick={handleFilterClick}
          fullWidth
        >
          {t('shipments:filter')}
        </FilterButton>
      </Grid>
      <Grid item md={12} xs={6}>
        <RfhButton
          aria-label='Reset filters'
          variant='block--outlined'
          onClick={resetFilters}
          fullWidth
        >
          {t('shipments:resetFilters')}
        </RfhButton>
      </Grid>
    </Grid>
  )
})
