import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material/styles'
import RfhButton, { RfhButtonProps } from '@rfh/ui/components/RfhButton/RfhButton'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

export const ExpandCollapseAllButton = styled(RfhButton)<
  {
    align: 'inline-end' | 'inline-start' | 'left' | 'none' | 'right'
  } & RfhButtonProps
>(({ align }) => ({
  float: align,
  padding: 0,
}))

export const Label = styled('div')(({ theme }) => ({
  fontWeight: 700,
  marginRight: theme.spacing(1),
}))

export const LayersAmountLabel = styled(Label)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}))

export const ShipmentCarrierIcon = styled('div')(({ theme }) => ({
  backgroundImage: 'url(assets/carrier.svg)',
  backgroundSize: 'cover',
  height: '26px',
  width: '24px',
  display: 'inline-flex',
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const ShipmentExpandIcon = styled(ExpandMoreIcon)<{
  expanded: number
  margin: number
}>(({ theme, expanded, margin }) => ({
  transform: expanded === 1 ? 'scaleY(-1)' : 'scaleY(1)',
  transition: 'transform ease-in-out 400ms',
  marginLeft: theme.spacing(margin),
}))

export const ShipmentPopper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  background: RfhColors.white,
  borderRadius: 2,
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
}))
