import { FC } from 'react'

import { Box, useMediaQuery, useTheme } from '@mui/material'
import { RfhTypography } from '@rfh/ui/components/RfhTypography'
import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

import { ColorCircle } from './ColorCircle'

export type Info = {
  topTotal: number
  topTitle: string
  leftTotal: number
  leftTitle: string
  rightTotal: number | null
  rightTitle: string
}

export const InfoCard: FC<{
  color: keyof typeof RfhColors
  info: Info
  firstChip: string
  secondChip: string
}> = ({ color, firstChip, secondChip, info, ...rest }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.only('xs'))
  const mediumScreen = useMediaQuery(theme.breakpoints.only('md'))
  const matchesScreenSize = smallScreen || mediumScreen

  return (
    <Box
      p={2}
      bgcolor={RfhColors.white}
      display={'flex'}
      data-testid={'info-card'}
      width={'100%'}
      height={'100%'}
      {...rest}
    >
      <ColorCircle
        color={color}
        text={info.topTotal}
        size={50}
        data-testid={'color-circle'}
      />
      <Box
        ml={2}
        display={'flex'}
        flexDirection={matchesScreenSize ? 'column' : 'row'}
        flexGrow={1}
        justifyContent={'space-between'}
      >
        <Box>
          <Box mb={1} color={RfhColors[color]}>
            <RfhTypography variant={'subtitle1'}>{info.topTitle}</RfhTypography>
          </Box>
          <Box display={'flex'} flexDirection={'row'}>
            <RfhTypography variant={'body-copy'}>
              <Box display={'inline'} mr={'4px'} color={RfhColors[color]}>
                <strong>{info.leftTotal}</strong>
              </Box>
              {info.leftTitle}
            </RfhTypography>
            {info.rightTotal !== null ? (
              <RfhTypography variant={'body-copy'}>
                <Box display={'inline'} mx={'4px'} color={RfhColors[color]}>
                  <strong>{info.rightTotal}</strong>
                </Box>
                {info.rightTitle}
              </RfhTypography>
            ) : null}
          </Box>
        </Box>
        <Chips
          firstChip={firstChip}
          secondChip={secondChip}
          matchesScreenSize={matchesScreenSize}
        />
      </Box>
    </Box>
  )
}

export const Chips: FC<{
  firstChip: string
  secondChip: string
  matchesScreenSize: boolean
}> = ({ firstChip, secondChip, matchesScreenSize }) => (
  <Box
    mt={matchesScreenSize ? 2 : 0}
    display={'flex'}
    flexDirection={matchesScreenSize ? 'row' : 'column'}
    alignItems='flex-end'
  >
    <Box
      borderRadius='50px'
      pb={'4px'}
      px={1}
      mr={matchesScreenSize ? 1 : 0}
      mb={matchesScreenSize ? 0 : 1}
      bgcolor={RfhColors.fogWhite}
      color={RfhColors.darkGrey}
      textAlign={'center'}
    >
      <RfhTypography variant={'caption-micro'}>{firstChip}</RfhTypography>
    </Box>
    <Box
      borderRadius='50px'
      pb={'4px'}
      px={1}
      color={RfhColors.darkGrey}
      bgcolor={RfhColors.fogWhite}
      textAlign={'center'}
    >
      <RfhTypography variant={'caption-micro'}>{secondChip}</RfhTypography>
    </Box>
  </Box>
)
