import React from 'react'

import { RfhColors } from '@rfh/ui/shared/styles/constants/colors'

export const ColorCircle = React.forwardRef<
  HTMLDivElement,
  {
    text: string | number
    color: keyof typeof RfhColors
    size?: number
  } & React.HTMLProps<HTMLDivElement>
>(({ color, text, size = 24, ...rest }, innerRef) => (
  <div
    style={{
      backgroundColor: RfhColors[color],
      width: size,
      height: size,
      borderRadius: '50%',
      color: RfhColors.white,
      fontSize: size * 0.6,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
    }}
    ref={innerRef}
    {...rest}
  >
    <span style={{ transform: `translateY(-${size * 0.05}px)` }}>{text}</span>
  </div>
))
