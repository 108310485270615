import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Container,
  ContainerProps,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import RfhFooter, { Language } from '@rfh/ui/components/RfhFooter/RfhFooter'
import { RfhHero } from '@rfh/ui/components/RfhHero'

import { AppBar } from 'src/common/components/AppBar'

import { Drawer } from './Drawer'

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(12),
})) as FC<Omit<ContainerProps, 'children'>>

export const Layout: FC = ({ children }) => {
  const { i18n, t } = useTranslation()
  const theme = useTheme()
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <AppBar />
      <RfhHero
        title={t('general:appBar.title')}
        subtitle={t('shipments:heroDescription')}
      />
      <StyledContainer maxWidth='xl'>{children}</StyledContainer>
      {bigScreen ? (
        <RfhFooter language={i18n.language as Language} container={'xl'} />
      ) : (
        <Drawer />
      )}
    </>
  )
}
