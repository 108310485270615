import { AxiosResponse } from 'axios'
import format from 'date-fns/format'

import { GlobalStore } from 'src/common/stores/GlobalStore'
import { ShipmentsApiResponse } from 'src/common/types/shipments'
import { Api } from 'src/common/utils/api'

export const getShipments = async ({
  date,
  rfhWarehouse,
  shipmentId,
  salesOrderId,
}: GlobalStore['filters']): Promise<AxiosResponse<ShipmentsApiResponse>> =>
  Api.get<ShipmentsApiResponse>(
    `/shipments/${format(date, 'yyyy-MM-dd')}T00:00:00.000Z/${rfhWarehouse.code}`,
    {
      params: {
        shipmentId,
        salesOrderId,
      },
    }
  )
