import { observer } from 'mobx-react-lite'

import { FC, MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MessageCard from '@rfh/ui/components/Cards/MessageCard'

import { useGlobalStore } from '../stores/GlobalStore'
import { Shipment } from '../types/shipments'
import { ShipmentsListHeader } from './ShipmentsListHeader'
import { ShipmentsListItem } from './ShipmentsListItem'

export const ShipmentsList: FC<{ shipments: Shipment[] }> = observer(
  ({ shipments }) => {
    const { t } = useTranslation()
    const { queryState } = useGlobalStore()
    const [shipmentsExpanded, setShipmentsExpanded] = useState<
      { id: string; expand: boolean }[]
    >([])
    const [shouldCollapseAll, setShouldCollapseAll] = useState<boolean>(false)

    useEffect(() => {
      const newShipmentsExpanded = shipments.map((shipment: Shipment) => {
        return { id: shipment.id, expand: false }
      })
      setShipmentsExpanded(newShipmentsExpanded)
    }, [shipments])

    useEffect(() => {
      const collapseAll = shipmentsExpanded.filter(({ expand }) => expand).length > 0
      setShouldCollapseAll(collapseAll)
    }, [shipmentsExpanded])

    const handleCollapseExpand = () => {
      const newShipmentsExpanded = shipmentsExpanded.map(shipmentExpanded => {
        return shouldCollapseAll
          ? { ...shipmentExpanded, expand: false }
          : { ...shipmentExpanded, expand: true }
      })
      setShipmentsExpanded(newShipmentsExpanded)
    }

    const handleListItemClick = (
      event: MouseEvent<HTMLDivElement>,
      selectedListId: undefined | string
    ) => {
      event.stopPropagation()

      const newShipmentsExpanded = shipmentsExpanded.map(({ id, expand, ...rest }) => {
        return {
          ...rest,
          id,
          expand: selectedListId === id ? !expand : expand,
        }
      })

      setShipmentsExpanded(newShipmentsExpanded)
    }
    const findShipmentExpanded = (shipmentID: string): boolean => {
      const foundShipmentExpanded = shipmentsExpanded.find(
        shipmentExpanded => shipmentExpanded.id === shipmentID
      )

      if (foundShipmentExpanded) {
        return foundShipmentExpanded.expand
      } else return false
    }

    return (
      <>
        <ShipmentsListHeader
          shouldCollapseAll={shouldCollapseAll}
          handleClick={handleCollapseExpand}
        />
        {queryState === 'found' ? (
          <>
            {shipments.map(shipment => (
              <ShipmentsListItem
                key={shipment.id}
                shipment={shipment}
                expand={findShipmentExpanded(shipment.id)}
                handleClick={event => {
                  handleListItemClick(event, shipment.id)
                }}
              />
            ))}
          </>
        ) : (
          <MessageCard
            title={t(`shipments:queryState.${queryState}.title`)}
            message={t(`shipments:queryState.${queryState}.message`)}
            mt={2}
          />
        )}
      </>
    )
  }
)
