import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { styled, Box } from '@mui/material'
import { RfhTypography } from '@rfh/ui/components/RfhTypography'

import { FilterForm } from './FilterForm'

const StyledDisplayBox = styled(Box)(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledTitleBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
}))

export const FilterDisplay: FC = () => {
  const { t } = useTranslation()

  return (
    <StyledDisplayBox>
      <StyledTitleBox>
        <RfhTypography variant='h5'>{t('shipments:filters')}</RfhTypography>
      </StyledTitleBox>
      <FilterForm />
    </StyledDisplayBox>
  )
}
