import { observer } from 'mobx-react-lite'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import TuneIcon from '@mui/icons-material/Tune'
import { styled, Box, SwipeableDrawer, IconButton } from '@mui/material'
import GlobalStyles from '@mui/material/GlobalStyles'
import { RfhTypography } from '@rfh/ui/components/RfhTypography'
import CloseOutline from '@rfh/ui/shared/icons/CloseOutline'

import { useGlobalStore } from '../stores/GlobalStore'
import { FilterForm } from './FilterForm'

const drawerBleeding = 56

const ClickContainer = styled(Box)(() => ({
  cursor: 'pointer',
  component: 'span',
}))

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.rfhColors.fogWhite,
  position: 'absolute',
  top: -drawerBleeding,
  visibility: 'visible',
  right: 0,
  left: 0,
  bottom: 0,
}))

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.rfhColors.white,
  padding: theme.spacing(2),
  boxShadow: '0px 0px 6px #00000029',
}))

const FilterIcon = styled(TuneIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  '& .MuiSvgIcon-root:hover': {
    color: theme.rfhColors.black,
  },
}))

const CloseBox = styled(Box)<{ open: number }>(({ open }) => ({
  position: 'absolute',
  right: 0,
  display: open === 1 ? 'block' : 'none',
  '& .MuiSvgIcon-root': {
    fontSize: '1rem',
  },
}))

export const Drawer: FC = observer(() => {
  const { t } = useTranslation()
  const { drawerIsOpen, setDrawerIsOpen } = useGlobalStore()

  return (
    <>
      <GlobalStyles
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 460,
            overflow: 'visible',
          },

          '.MuiDrawer-root': {
            zIndex: '0 !important',
          },
        }}
      />
      <ClickContainer
        role='button'
        aria-label='opens swipable drawer'
        onClick={() => !drawerIsOpen && setDrawerIsOpen(true)}
      >
        <SwipeableDrawer
          anchor='bottom'
          open={drawerIsOpen}
          onClose={() => setDrawerIsOpen(false)}
          onOpen={() => setDrawerIsOpen(true)}
          disableSwipeToOpen={false}
          SwipeAreaProps={{ style: { zIndex: 0, height: drawerBleeding } }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <StyledBox>
            <Header>
              <FilterIcon />
              <RfhTypography variant='caption-micro-bold'>
                {t('shipments:filter')}
              </RfhTypography>
              <CloseBox open={drawerIsOpen ? 1 : 0}>
                <IconButton
                  aria-label='close drawer'
                  component='span'
                  onClick={() => setDrawerIsOpen(false)}
                  size='large'
                >
                  <CloseOutline />
                </IconButton>
              </CloseBox>
            </Header>

            <Box px={3} pt={3}>
              <FilterForm />
            </Box>
          </StyledBox>
        </SwipeableDrawer>
      </ClickContainer>
    </>
  )
})
