import { CustomDetector } from 'i18next-browser-languagedetector'

import { allowedLanguages, fallbackLng } from './config'

export const customLanguageDetector: CustomDetector = {
  name: 'customLanguageDetector',

  lookup(_options) {
    const browserLng = navigator.language.substring(0, 2)

    return allowedLanguages.includes(browserLng) ? browserLng : fallbackLng
  },

  cacheUserLanguage(lng, _options) {
    localStorage.setItem('i18nextLng', lng)
  },
}
