import { useLocalObservable } from 'mobx-react-lite'

import { createContext, FC, useContext } from 'react'

import { AuthState, UserClaims } from '@okta/okta-auth-js'
import { AccessToken, IDToken } from '@okta/okta-auth-js/lib/types/Token'

export type ExtendedUserClaims = UserClaims & {
  customerNumber: number
}

export interface AccountStore {
  user?: ExtendedUserClaims
  accessToken?: AccessToken
  idToken?: IDToken
  isAuthenticated?: boolean
  permittedScopes?: string[]
  setPermittedScopes: (actor: any) => void
  authStateReady?: boolean
  buyer?: string
  setUser: (user: ExtendedUserClaims) => void
  setAuthState: (authState: AuthState) => void
  setAuthStateReady: (isReady: boolean) => void
  setBuyer: (buyer: string) => void
}

export const AccountStoreContext = createContext<AccountStore | null>(null)

// Store as component
export const AccountStoreProvider: FC = ({ children }) => {
  const accountStore: AccountStore = useLocalObservable(
    () =>
      ({
        setUser(user) {
          this.user = user
        },
        setAuthState(authState) {
          this.accessToken = authState.accessToken
          this.idToken = authState.idToken
          this.isAuthenticated = authState.isAuthenticated
        },
        setPermittedScopes(scopes: string[]) {
          this.permittedScopes = scopes
        },
        setAuthStateReady(isReady) {
          this.authStateReady = isReady
        },
        setBuyer(buyer) {
          this.buyer = buyer
        },
      } as AccountStore)
  )

  return (
    <AccountStoreContext.Provider value={accountStore}>
      {children}
    </AccountStoreContext.Provider>
  )
}

export const useAccountStore = (): AccountStore => {
  const store = useContext(AccountStoreContext)

  if (!store) {
    throw new Error('No account store defined')
  }

  return store
}
