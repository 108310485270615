import { FC } from 'react'
import { Redirect } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

export const Login: FC = () => {
  const { authState, oktaAuth } = useOktaAuth()

  if (!authState) {
    return <>Loading...</>
  }

  if (!authState.isAuthenticated) {
    oktaAuth.signInWithRedirect()
    return null
  }

  return <Redirect to='/' />
}
