import { observer } from 'mobx-react-lite'

import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid } from '@mui/material'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

import { FilterDisplay } from 'src/common/components/FilterDisplay'
import { FilterHeader } from 'src/common/components/FilterHeader'
import { Layout } from 'src/common/components/Layout'
import { ShipmentsList } from 'src/common/components/ShipmentsList'
import { ShipmentsTotals } from 'src/common/components/ShipmentsTotals'
import { useLoadMoreListData } from 'src/common/hooks/useLoadMoreListData'
import { useShipments } from 'src/common/hooks/useShipments'
import { useUserInformation } from 'src/common/hooks/useUserInformation'
import { useGlobalStore } from 'src/common/stores/GlobalStore'

export const Shipments: FC = observer(() => {
  const { shipments } = useGlobalStore()

  useUserInformation()

  const { t } = useTranslation()

  useShipments()

  const { newList, loadMoreShipmentOrders, hasShowMore } = useLoadMoreListData({
    listData: shipments,
    initShowNumberOfItems: 25,
    numberOfItemsToLoad: 25,
  })

  return (
    <Layout>
      <Grid container>
        <Grid item md={3} xs={12}>
          <FilterHeader message={t('shipments:totalsDescription')} />
        </Grid>
        <Grid item md={1} />
        <Grid item md={8} xs={12}>
          <ShipmentsTotals />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={3} xs>
          <FilterDisplay />
        </Grid>
        <Grid item md={1} />
        <Grid item md={8} xs={12}>
          <ShipmentsList shipments={newList} />
          {hasShowMore && (
            <Box mt={2}>
              <RfhButton
                fullWidth
                variant={'block--contained'}
                onClick={loadMoreShipmentOrders}
              >
                {t('shipments:loadMoreOrders')}
              </RfhButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </Layout>
  )
})
