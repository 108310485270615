import { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { useOktaAuth } from '@okta/okta-react'

export const Logout: FC = () => {
  const { oktaAuth } = useOktaAuth()
  const history = useHistory()

  const logoutAndClearSession = async () => {
    oktaAuth.tokenManager.clear()
    await oktaAuth.signOut()
    history.push('/')
    // force reload otherwise react doe not trigger any change event
    history.go(0)
  }

  logoutAndClearSession()

  return null
}
