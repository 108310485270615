import { observer } from 'mobx-react-lite'

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { CallToActionIcon } from '@rfh/ui/components/CallToActionIcon'
import RfhAppBar from '@rfh/ui/components/Layout/RfhAppBar'
import { RfhPrimaryMenuProps } from '@rfh/ui/components/RfhPrimaryMenu/RfhPrimaryMenu'
import MenuNavigationOutline from '@rfh/ui/shared/icons/MenuNavigationOutline'

import { appLanguageConfig } from '../../i18n/config'
import { useAccountStore } from '../stores/AccountStore'
import { useGlobalStore } from '../stores/GlobalStore'

export const AppBar: FC = observer(() => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const { setDrawerIsOpen } = useGlobalStore()
  const { user } = useAccountStore()

  const publicPrimaryMenu: RfhPrimaryMenuProps = {
    content: (
      <CallToActionIcon
        title={user?.family_name as string}
        linkText={t('general:primaryMenu.items.logout.label')}
        onActionClick={_event => {
          history.push('/logout')
        }}
      />
    ),
    items: [
      {
        text: t('general:primaryMenu.items.about.label'),
        iconEnd: <MenuNavigationOutline />,
        onClick: () => window.open(t('general:primaryMenu.items.about.url')),
      },
      {
        text: t('general:primaryMenu.items.contact.label'),
        iconEnd: <MenuNavigationOutline />,
        onClick: () => window.open(t('general:primaryMenu.items.contact.url')),
      },
    ],
  }

  return (
    <RfhAppBar
      menuOpen={menuOpen}
      title={menuOpen ? t('general:appBar.mainMenu') : t('general:appBar.title')}
      primaryMenu={publicPrimaryMenu}
      customLanguageMenu={appLanguageConfig}
      activeLanguage={i18n.language}
      // eslint-disable-next-line @typescript-eslint/unbound-method
      changeLanguage={i18n.changeLanguage}
      onLogoClick={() => {
        history.push('/')
        // force reload otherwise react doe not trigger any change event
        history.go(0)
      }}
      onMenuOpen={() => {
        setMenuOpen(true)
        setDrawerIsOpen(false)
      }}
      onMenuClose={() => {
        setMenuOpen(false)
      }}
    />
  )
})
