import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { EmptyState, EmptyStateIcon } from '@rfh/ui/components/EmptyState'
import RfhButton from '@rfh/ui/components/RfhButton/RfhButton'

import { Layout } from '../common/components/Layout'

export const NotFound: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Layout>
      <EmptyState
        bottomComponent={
          <RfhButton
            variant='block--contained'
            onClick={() => {
              history.push('/')
            }}
          >
            {t('general:notFound.button')}
          </RfhButton>
        }
        graphic={<EmptyStateIcon />}
        header={t('general:notFound.header')}
        subheader={t('general:notFound.subheader')}
      />
    </Layout>
  )
}
